import Colors from './Colors'

const XS = 'xs'
const SM = 'sm'
const MD = 'md'
const LG = 'lg'
const XL = 'xl'

export const BREAKPOINTS = {
  [XS]: 0,
  [SM]: 600,
  [MD]: 960,
  [LG]: 1280,
  [XL]: 1920,
}

const BREAKPOINT_MARGIN = 0.05

const DOWN_SIZES = {
  [XS]: `@media (max-width:${BREAKPOINTS[SM] - BREAKPOINT_MARGIN}px)`,
  [SM]: `@media (max-width:${BREAKPOINTS[MD] - BREAKPOINT_MARGIN}px)`,
  [MD]: `@media (max-width:${BREAKPOINTS[LG] - BREAKPOINT_MARGIN}px)`,
  [LG]: `@media (max-width:${BREAKPOINTS[XL] - BREAKPOINT_MARGIN}px)`,
  [XL]: '@media (min-width:0px)',
}

const UP_SIZES = {
  [XS]: '@media (min-width:0px)',
  [SM]: `@media (min-width:${BREAKPOINTS[SM]}px)`,
  [MD]: `@media (min-width:${BREAKPOINTS[MD]}px)`,
  [LG]: `@media (min-width:${BREAKPOINTS[LG]}px)`,
  [XL]: `@media (min-width:${BREAKPOINTS[XL]}px)`,
}

export default {
  white: Colors.white,

  // Brand colors
  brandPrimary: '#1976D1',

  primaryPink: Colors.primaryPink,
  primaryCharcoal: Colors.primaryCharcoal,
  primaryGray: Colors.primaryGray,
  borderGray: Colors.borderGray,
  backgroundGray: Colors.backgroundGray,
  spacing: {
    unit: 8,
  },
  breakpoints: {
    down: (size) => DOWN_SIZES[size],
    up: (size) => UP_SIZES[size],
  },
}
