export default {
  'nav.table-name': 'Table',
  'nav.call': 'Call',
  'nav.bill': 'Bill',
  'nav.menu': 'Menu',
  'nav.settings': 'Settings',
  'index.closed': 'Closed',
  'profile.logout': 'Logout',
  'settings.title': 'Settings',
  'settings.email': 'Email',
  'settings.password': 'Password',
  'settings.password-warning': 'Single-byte characters only',
  'settings.password-confirmation': 'Confirm Password',
  'settings.password-repeat': 'Please enter your password again',
  'settings.name': 'Name',
  'settings.save': 'Save',
  'order.title': 'Confirm order',
  'order.items': 'Items',
  'order.status': 'Total',
  'order.cta': 'Order',
  'meal-modal.option-price': '+{price}円 ({total}円 with tax)',
  'meal-modal.add-to-cart-cta': 'Add to cart ({price})',
  'cart.items': 'Items',
  'cart.status': 'Total',
  'cart.cta': 'Order',
  'login.title': 'Login',
  'login.subtitle': 'To order from your phone, you must log in to your account',
  'login.line-login-button': 'Use with a LINE account',
  'login.or': 'or',
  'login.email-address': 'Email address',
  'login.email': 'Email',
  'login.password': 'Password',
  'login.password-hint': 'Please use single-byte alphanumeric characters',
  'login.login-prompt': 'Login',
  'login.forgot-password': 'Forgot password?',
  'login.signup': 'Don\'t have an account?',
  'login.signup-prompt': 'Create account',
  'login.guest-prompt': 'Continue as guest',
  'signup.title': 'Signup',
  'signup.email': 'Email',
  'signup.password': 'Password',
  'signup.password-hint': 'Please enter at least 6 alphanumeric characters',
  'signup.confirm-password': 'Confirm password',
  'signup.confirm-password-hint': 'Please enter your password again',
  'signup.name': 'Name',
  'signup.terms-hint': 'I agree to the {termsLink} and {privacyLink}',
  'signup.terms': 'Terms of use',
  'signup.privacy-policy': 'Privacy policy',
  'signup.save': 'Save',
  'checkout.title': 'Checkout bill',
  'checkout.cta': 'Next',
  'checkout-modal.title': 'Payment method',
  'checkout-modal.pay-in-app': 'Pay in app',
  'checkout-modal.pay-at-register': 'Pay at register',
  'checkout-modal.cancel': 'Cancel',
  'checkout-mobile.title': 'Pay in app',
  'checkout-mobile.card-information': 'Card information',
  'checkout-mobile.change-card': 'Change card',
  'checkout-mobile.cvc-hint': 'This is the last 3 or 4 digits printed on the back of the card in your signature or other signature box',
  'checkout-mobile.save-card': 'Save card',
  'checkout-mobile.pay-cta': 'Pay',

  'checkout-store.title': 'Pay in shop',
  'checkout-store.instructions': 'Please show this screen to our staff',
  'checkout-store.number-of-items': 'Items',
  'checkout-store.subtotal': 'Subtotal',

  'order-complete.title': 'Thank you!',
  'order-complete.subtitle': 'We hope that you have enjoyed your meal. Please come again!',
  'order-complete.cta': 'Start a new order',

  'start-order-form.title': 'Welcome to our store!',
  'start-order-form.subtitle': 'Please select the number of people visiting the store and press check-in',
  'start-order-form.cta': 'Check in',
  'orders-subtotal.number-of-items': 'Items',
  'orders-subtotal.subtotal': 'Subtotal',
  'orders-subtotal.customer-name': 'Customer: {customerName}',
  'call-modal.title': 'Would you like to call the attendant?',
  'call-modal.cta-primary': 'Call',
  'call-modal.cta-secondary': 'Cancel',
  'call-modal.confirmed': 'I will be with you shortly. Please wait a moment.',
  'call-modal.cta-close': 'Close',
}
