export const camelCase = (snake) =>
  snake.toLowerCase().replace(/(_\w)/g, (k) => k[1].toUpperCase())
export const getRequestType = name => `REQUEST_${name}`;
export const getReceiveType = name => `RECEIVE_${name}`;

const DEFAULT_OPTIONS = {
  key: 'default',
  useCache: false,
}

const apiVersion = 8
const source = 'web'

export default (name, api, options) => {
  const { key, useCache } = {
    ...DEFAULT_OPTIONS,
    ...options,
  }

  const requestType = getRequestType(name)
  const receiveType = getReceiveType(name)

  const reducerKey = camelCase(name)

  const selector = (state) => state.api[reducerKey][key]
  const locationSelector = (state) => state.features.location

  const request = (...args) => ({ ...args, type: requestType, key })
  const recieve = (data, error) => ({
    type: receiveType,
    data,
    error,
    key,
  })

  return (dispatch, getState) => {
    const state = getState()

    if (useCache) {
      const apiState = selector(state)

      if (apiState && apiState.data) {
        return apiState.data
      }
    }

    dispatch(request())

    const location = locationSelector(state)

    const commonProps = {
      apiVersion,
      source,
      latitude: location?.latitude,
      longitude: location?.longitude,
    }

    const axiosOptions = {}

    /*
     *  Server side only
     */
    if (!process.browser) {
      const cookieSelector = (state) => state.features.cookie
      const cookie = cookieSelector(state)

      if (cookie) {
        axiosOptions.headers = {
          cookie,
        }
      }
    }

    return api(dispatch, commonProps, axiosOptions)
      .then((response) => (response?.json ? response.json() : response))
      .then((json) => {
        const data = json?.data ? json?.data : json
        dispatch(recieve(data, null))
        return data
      })
      .catch(async (error) => {
        dispatch(recieve(null, error?.response?.data || error.toJSON()))
      })
  }
}
