import io from 'socket.io-client'
import { store } from '../redux/store'
import {
  setSocketConnectionStatus,
} from '../redux/features/actions'
import {
  mergeDataFromSocketIO,
} from '../redux/api/actions'
import {
  channelTypes,
  socketConnectionStatuses,
} from './Constants'

let HOME_URL

if (process.browser && location.hostname.includes('localhost')) {
  HOME_URL = 'http://dev-api.to.pot-luck.jp'
} else {
  HOME_URL = 'https://dev-api.to.pot-luck.jp'
}

const messageTypes = {
  API_ACTION: 'api-action',
  NOTIFICATION: 'notification',
  DIALOG: 'dialog',
}

let socket

export const openSocketConnection = async ({ code }) => {
  console.log('Connecting to Socket!! ', HOME_URL)

  if (!HOME_URL) {
    await configure()
  }

  try {
    socket = io(HOME_URL, {
      path: `/ws`,
      transports: ['websocket'],
      jsonp: false,
    })

    socket.on('connect', () => {
      socket.emit('join', {
        channelType: channelTypes.ORDER,
        code,
      })

      store?.dispatch(setSocketConnectionStatus(socketConnectionStatuses.CONNECTED))
    })

    socket.on('data', data => {
      console.log('socket: data received!', data)

      store?.dispatch(setSocketConnectionStatus(socketConnectionStatuses.CONNECTED))

      if (data.type === messageTypes.API_ACTION) {
        store?.dispatch(mergeDataFromSocketIO(data))
      }
    })

    socket.on('error', error => {
      store?.dispatch(setSocketConnectionStatus(socketConnectionStatuses.DISCONNECTED))

      console.log('socket: error')
      console.log(error)
    })

    socket.on('connect_error', error => {
      store?.dispatch(setSocketConnectionStatus(socketConnectionStatuses.DISCONNECTED))

      console.log('socket: connect_error')
      console.log(error)

      setTimeout(() => {
        socket.connect()
      }, 1000)
    })
  } catch (error) {
    console.log('socket: error')
    console.log(error)
  }
}
