export default (options=[], otherOptions=[]) => {
  if (!options && !otherOptions) {
    return true
  }

  return options.length === otherOptions.length
  && Object.keys(options).every(optionName =>
    otherOptions[optionName].name === options[optionName].name
    && otherOptions[optionName].value.name === options[optionName].value.name)
}
