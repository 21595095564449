import { combineReducers } from 'redux'

import makeReducer from '../tools/makeReducer'
import { ACTIONS } from './actions'

export const camelCase = (snake) =>
  snake.toLowerCase().replace(/(_\w)/g, (k) => k[1].toUpperCase())

const reducers = Object.values(ACTIONS).reduce(
  (memo, name) => ({
    ...memo,
    [camelCase(name)]: makeReducer(name),
  }),
  {},
)

export default combineReducers(reducers)
