import { HYDRATE, createWrapper } from 'next-redux-wrapper'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'

import api from './api/reducer'
import features from './features/reducer'

const dataReducers = combineReducers({
  api,
  features,
})

const reducer = (state = {}, action) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
    }
  }

  return dataReducers(state, action)
}

export const store = createStore(reducer, applyMiddleware(thunk))

// create a makeStore function
const makeStore = () => store

// export an assembled wrapper
export const wrapper = createWrapper(makeStore)
