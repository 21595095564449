export const MIN_PASSWORD_LENGTH = 6

export const PAY_JP_PUBLIC_KEY_TEST = 'pk_test_0409bcf2bb48798156e98527'
export const PAY_JP_PUBLIC_KEY = 'pk_live_e035c864f5b5413818faf826'

export const menuTypes = {
  MENU_ITEM: 'MENU_ITEM',
  COURSE: 'COURSE',
}

export const orderStatuses = {
  OPEN: 'OPEN',
  PENDING: 'PENDING',
  CLOSED: 'CLOSED',
}

export const CC_FORMAT_MAP = {
  Visa: '•••• •••• •••• ',
  MasterCard: '•••• •••• •••• ',
  JCB: '•••• •••• •••• ',
  'American Express': '•••• •••••• •',
  'Diners Club': '•••• ••••••• ',
  Discover: '•••• •••• •••• ',
}

export const LINE_REDIRECT_URI_BASE = 'https://to.pot-luck.jp'

export const LOCALE_JA = 'ja'
export const LOCALE_EN = 'en'

export const socketConnectionStatuses = {
  INITIAL: 'initial',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
}

export const channelTypes = {
  SHOP: 'SHOP',
  TABLE: 'TABLE',
  ORDER: 'ORDER',
}

export const PLACEHOLDER_IMAGE = 'https://table-orders-media.s3.ap-northeast-1.amazonaws.com/placeholder.jpeg'
