import axios from 'axios'
import queryString from 'query-string'

import apiAction, {getReceiveType} from '../tools/apiAction'

export const ACTIONS = {
  SIGNUP: 'SIGNUP',
  LOGIN: 'LOGIN',
  LOGIN_AS_GUEST: 'LOGIN_AS_GUEST',
  CONTINUE_WITH_LINE: 'CONTINUE_WITH_LINE',
  LOGOUT: 'LOGOUT',
  PROFILE: 'PROFILE',
  CREATE_CARD: 'CREATE_CARD',
  SHOP: 'SHOP',
  TABLE: 'TABLE',
  SHOP_STAFFS: 'SHOP_STAFFS',
  MENU: 'MENU',
  ORDER: 'ORDER',
  CREATE_ORDER: 'CREATE_ORDER',
  CREATE_ORDER_AS_GUEST: 'CREATE_ORDER_AS_GUEST',
  PRINT_TEMPORARY_ACCOUNTING_SLIP: 'PRINT_TEMPORARY_ACCOUNTING_SLIP',
  CALL_WAITER: 'CALL_WAITER',
}

let BASE_URL = '/_api'

/* for server-side render */
if (!process.browser) {
  BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || "http://table-orders-api-83191762.ap-northeast-1.elb.amazonaws.com"
}

/*
 * Helper functions to create api functions
 */
const makeGetRequest = (action, baseUrl, options = {}, flags = {}) =>
  apiAction(
    action,
    (dispatch, commonProps, axiosOptions = {}) => {
      const apiCall = axios.get(`${baseUrl}?${queryString.stringify(commonProps)}`, {
        withCredentials: true,
        ...axiosOptions,
      })

      if (flags?.debug) {
        apiCall.then(response => {
          console.log(`Response: ${action}  ================`)
          console.log(`${baseUrl}?${queryString.stringify(commonProps)}`)
          console.log(response)
          console.log(`===========================================`)
          return response
        }).catch(error => {
          console.log(`Error:    ${action}  ================`)
          console.log(`${baseUrl}?${queryString.stringify(commonProps)}`)
          console.log(error)
          console.log(`===========================================`)
          return error
        })
      }

      return apiCall
    },
    options,
  )

const makePostRequest = (action, baseUrl, body = {}, options = {}) =>
  apiAction(
    action,
    (dispatch, commonProps, axiosOptions = {}) => {
      return axios.post(
        baseUrl,
        {
          ...commonProps,
          ...body,
        },
        { withCredentials: true, ...axiosOptions },
      )
    },
    options,
  )

const makeDeleteRequest = (action, baseUrl, body = {}, options = {}) =>
  apiAction(
    action,
    (dispatch, commonProps, axiosOptions = {}) => {
      return axios.delete(baseUrl, {
        withCredentials: true,
        data: {
          ...commonProps,
          ...body,
        },
        ...axiosOptions,
      })
    },
    options,
  )

export const mergeDataFromSocketIO = ({action, key, data}) => ({
  type: getReceiveType(action),
  key,
  data,
})

export const signup = (user) =>
  makePostRequest(ACTIONS.SIGNUP, `${BASE_URL}/v1/auth/signup`, user)
export const login = (user) =>
  makePostRequest(ACTIONS.LOGIN, `${BASE_URL}/v1/auth/login`, user)
export const loginAsGuest = () =>
  makePostRequest(ACTIONS.LOGIN_AS_GUEST, `${BASE_URL}/v1/auth/login-as-guest`, {})
export const continueWithLine = body =>
  makePostRequest(ACTIONS.CONTINUE_WITH_LINE, `${BASE_URL}/v1/line/continue`, body)
export const logout = () =>
  makePostRequest(ACTIONS.LOGOUT, `${BASE_URL}/v1/auth/logout`, {})
export const getProfile = () =>
  makeGetRequest(ACTIONS.PROFILE, `${BASE_URL}/v1/profile`)
export const getProfileIfNeeded = () =>
  makeGetRequest(ACTIONS.PROFILE, `${BASE_URL}/v1/profile`, { useCache: true })
export const createCard = (token) =>
  makePostRequest(ACTIONS.CREATE_CARD, `${BASE_URL}/v1/profile/cards`, {
    token,
  })
export const setDefaultCard = (cardId) =>
  makePostRequest(ACTIONS.CREATE_CARD, `${BASE_URL}/v1/profile/default-card`, {
    cardId,
  })
export const getShop = (publicId) =>
  makeGetRequest(ACTIONS.SHOP, `${BASE_URL}/v1/shops/${publicId}`)
export const getShopIfNeeded = (publicId) =>
  makeGetRequest(ACTIONS.SHOP, `${BASE_URL}/v1/shops/${publicId}`, {
    useCache: true,
    key: publicId,
  })
export const getTableIfNeeded = (shopPublicId, tableCode) =>
  makeGetRequest(
    ACTIONS.TABLE,
    `${BASE_URL}/v1/shops/${shopPublicId}/tables/${tableCode}`,
    {
      useCache: true,
      key: `${shopPublicId}-${tableCode}`,
    },
  )
export const getShopStaffs = shopPublicId => makeGetRequest(ACTIONS.SHOP_STAFFS, `${BASE_URL}/v1/${shopPublicId}/shop-staffs`, {
  key: shopPublicId,
})
export const getMenu = (publicId) =>
  makeGetRequest(ACTIONS.MENU, `${BASE_URL}/v1/shops/${publicId}/menu`, {
    key: publicId,
  })
export const getMenuIfNeeded = (publicId) =>
  makeGetRequest(ACTIONS.MENU, `${BASE_URL}/v1/shops/${publicId}/menu`, {
    useCache: true,
    key: publicId,
  })
export const getOrder = (shopPublicId, tableCode) =>
  makeGetRequest(
    ACTIONS.ORDER,
    `${BASE_URL}/v1/orders/${shopPublicId}/${tableCode}/open`,
  )
export const getOrderIfNeeded = (shopPublicId, tableCode) =>
  makeGetRequest(
    ACTIONS.ORDER,
    `${BASE_URL}/v1/orders/${shopPublicId}/${tableCode}/open`,
    { useCache: true },
  )
export const createOrder = (values) =>
  makePostRequest(ACTIONS.CREATE_ORDER, `${BASE_URL}/v1/orders`, values)
export const printTemporaryAccountingSlip = orderCode =>
  makePostRequest(ACTIONS.CREATE_ORDER, `${BASE_URL}/v1/printers/print-temporary-accounting-slip/${orderCode}`)

export const payForOrder = code =>
  makePostRequest(
    ACTIONS.CREATE_ORDER,
    `${BASE_URL}/v1/orders/${code}/pay`,
  )
export const payForOrderAsGuest = (code, data) =>
  makePostRequest(
    ACTIONS.CREATE_ORDER_AS_GUEST,
    `${BASE_URL}/v1/orders/${code}/pay-as-guest`, data)
export const callWaiter = code =>
  makePostRequest(
    ACTIONS.CALL_WAITER,
    `${BASE_URL}/v1/orders/${code}/call-waiter`,
  )

export const getOrderByCode = (orderCode) => makeGetRequest(ACTIONS.ORDER, `${BASE_URL}/v1/orders/${orderCode}`)
export const getShopByOrderCodeIfNeeded = (orderCode) =>
  makeGetRequest(ACTIONS.SHOP, `${BASE_URL}/v1/shops/by-order/${orderCode}`, {
    useCache: true,
    key: orderCode,
  })
export const getTableByOrderCodeIfNeeded = (orderCode) =>
  makeGetRequest(ACTIONS.TABLE, `${BASE_URL}/v1/shops/by-order/${orderCode}/table`, {
    useCache: true,
    key: orderCode,
  })
export const getMenuByOrderCode = (orderCode) =>
  makeGetRequest(ACTIONS.MENU, `${BASE_URL}/v1/shops/by-order/${orderCode}/menu`, {
    key: orderCode,
  })
export const getShopStaffsByOrderCode = orderCode =>
  makeGetRequest(ACTIONS.SHOP_STAFFS, `${BASE_URL}/v1/by-order/${orderCode}/shop-staffs`, {
    key: orderCode,
  })
