import isEqual from 'lodash.isequal'
import optionsEqual from '../../utils/optionsEqual'

import {
  LOCALE_JA,
} from '../../utils/Constants'

// import { orderTypes } from '../../utils/Constants'
import {
  SET_LOCALE,
  CLEAR_CART,
  CLOSE_DIALOG,
  RESET,
  SET_CANCELATION_SURVEY,
  SET_COOKIE,
  SET_DEFAULT_OFFER_CODE,
  SET_ITEM_QUANTITY_IN_CART,
  SET_LOCATION,
  SET_TABLE,
  SHOW_DIALOG,
  TOGGLE_ORDER_SENT,
} from './actions'

const getInitialState = () => ({
  locale: LOCALE_JA,
  cart: {
    items: [],
  },
  table: '',
  orderSent: false,
  dialog: null,
})

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET: {
      return getInitialState()
    }
    case SET_LOCALE: {
      return {
        ...state,
        locale: action.locale,
      }
    }
    case SET_COOKIE: {
      return {
        ...state,
        cookie: action.cookie,
      }
    }
    case SET_LOCATION: {
      return {
        ...state,
        location: action.location,
      }
    }
    case SET_ITEM_QUANTITY_IN_CART: {
      const existingIndex = state.cart.items.findIndex(item => item.id === action.item.id && optionsEqual(item.options, action.item.options))

      if (action.quantity > 0) {
        if (existingIndex === -1) {
          return {
            ...state,
            cart: {
              items: [
                ...state.cart.items,
                {
                  ...action.item,
                  quantity: action.quantity,
                },
              ],
            },
          }
        }

        return {
          ...state,
          cart: {
            items: state.cart.items.map((item, index) => {
              if (index === existingIndex) {
                return {
                  ...item,
                  quantity: action.quantity,
                }
              }

              return item
            }),
          },
        }
      } else {
        return {
          ...state,
          cart: {
            items: state.cart.items.filter((item, index) => index !== existingIndex),
          },
        }
      }
    }
    case CLEAR_CART: {
      return {
        ...state,
        cart: {
          items: [],
        },
      }
    }
    case SET_TABLE: {
      return {
        ...state,
        table: action.table,
      }
    }
    case TOGGLE_ORDER_SENT: {
      return {
        ...state,
        orderSent: action.orderSent,
      }
    }
    case SHOW_DIALOG: {
      return {
        ...state,
        dialog: {
          isOpen: true,
          ...action.dialog,
        },
      }
    }
    case CLOSE_DIALOG: {
      return {
        ...state,
        dialog: null,
      }
    }
    case SET_DEFAULT_OFFER_CODE: {
      return {
        ...state,
        defaultOfferCode: action.defaultOfferCode,
      }
    }
    default:
      return state
  }
}
