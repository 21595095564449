import moment from 'moment-timezone'
import Router, {
  withRouter,
  useRouter,
} from 'next/router'
import React, {
  useRef,
  useState,
  useEffect,
  Component,
} from 'react'
import { IntlProvider, injectIntl } from 'react-intl'
import { ThemeProvider } from 'react-jss'
import { connect } from 'react-redux'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import locales from '../i18n/locales'
import { wrapper } from '../redux/store'
import '../styles/globals.css'
import Theme from '../utils/theme'
import {
  openSocketConnection,
} from '../utils/SocketIO'
import { orderStatuses } from '../utils/Constants'

const usePrevious = value => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const getReleaseStage = () => {
  let env = 'production'

  if (process.browser) {
    if (location.hostname.includes('localhost')) {
      return 'local'
    } else if (location.hostname.includes('dev')) {
      return 'development'
    }

    return 'production'
  }

  return process.env.BUGSNAG_STAGE || process.env.NODE_ENV || 'local'
}

Bugsnag.start({
  apiKey: 'bc09b95e501a54d5f8d79c58ebffa96b',
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: getReleaseStage(),
  enabledReleaseStages: ['development', 'production'],
  notifyReleaseStages: ['development', 'production'],
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

function App({ Component, pageProps, order }) {
  const router = useRouter()
  const prevOrder = usePrevious(order)

  useEffect(() => {
    if (process.browser && pageProps.query?.code) {
      openSocketConnection({
        code: pageProps.query?.code,
      })
    }
  }, [pageProps.query?.code])

  useEffect(() => {
    if (order?.data?.status !== prevOrder?.status  && order?.data?.status === orderStatuses.CLOSED) {
      router.replace(`/order/${order.data.code}`)
    }
  }, [order?.data?.status])

  return (
    <ErrorBoundary>
      <ThemeProvider theme={Theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </ErrorBoundary>
  )
}

const AppIntl = injectIntl(App)

class AppWrapper extends Component {
  render() {
    moment.locale(this.props.locale)
    const messages = locales[this.props.locale]

    return (
      <IntlProvider locale={this.props.locale} key={this.props.locale} messages={messages}>
        <AppIntl {...this.props} />
      </IntlProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  locale: state.features.locale,
  profile: state.api.profile.default,
  order: state.api.order.default,
})

export default wrapper.withRedux(
  withRouter(connect(mapStateToProps)(AppWrapper)),
)
