/* Special action to reset all of redux state */
export const RESET = 'RESET'

export const SET_LOCALE = 'SET_LOCALE'
export const SET_COOKIE = 'SET_COOKIE'
export const SET_SOCKET_CONNETION_STATUS = 'SET_SOCKET_CONNETION_STATUS'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_ITEM_QUANTITY_IN_CART = 'SET_ITEM_QUANTITY_IN_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const SET_TABLE = 'SET_TABLE'
export const TOGGLE_ORDER_SENT = 'TOGGLE_ORDER_SENT'
export const SET_CANCELATION_SURVEY = 'SET_CANCELATION_SURVEY'
export const SHOW_DIALOG = 'SHOW_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'
export const SET_DEFAULT_OFFER_CODE = 'SET_DEFAULT_OFFER_CODE'

export const reset = () => ({ type: RESET })

export const setLocale = (locale) => ({
  locale,
  type: SET_LOCALE,
})

export const setCookie = (cookie) => ({
  cookie,
  type: SET_COOKIE,
})

export const setSocketConnectionStatus = status => ({ type: SET_SOCKET_CONNETION_STATUS, status })

export const setLocation = (location) => ({
  location,
  type: SET_LOCATION,
})

export const setItemQuantityInCart = (item, quantity) => ({
  type: SET_ITEM_QUANTITY_IN_CART,
  item,
  quantity,
})
export const clearCart = () => ({ type: CLEAR_CART })

export const setTable = (table) => ({ type: SET_TABLE, table })
export const toggleOrderSent = (orderSent) => ({
  type: TOGGLE_ORDER_SENT,
  orderSent,
})
export const setCancelationSurvey = (cancelationSurvey) => ({
  type: SET_CANCELATION_SURVEY,
  cancelationSurvey,
})
export const showDialog = (dialog) => ({ type: SHOW_DIALOG, dialog })
export const closeDialog = () => ({ type: CLOSE_DIALOG })
export const setDefaultOfferCode = (defaultOfferCode) => ({
  type: SET_DEFAULT_OFFER_CODE,
  defaultOfferCode,
})
