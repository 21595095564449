export default {
  'nav.table-name': '席番号',
  'nav.call': '呼出',
  'nav.bill': 'お会計',
  'nav.menu': 'メニュー',
  'nav.settings': '設定',
  'index.closed': '売り切れ',
  'profile.logout': 'ログアウト',
  'settings.title': '設定',
  'settings.email': 'メールアドレス',
  'settings.password': 'パスワード',
  'settings.password-warning': '半角英数字で入力してください',
  'settings.password-confirmation': 'パスワードの確認',
  'settings.password-repeat': '再度パスワードを入力してください',
  'settings.name': 'お名前',
  'settings.save': '設定の変更を保存',
  'order.title': '注文確認',
  'order.items': '点',
  'order.status': '小計',
  'order.cta': '注文',
  'meal-modal.option-price': '+{price}円 (税込{total}円)',
  'meal-modal.add-to-cart-cta': '追加 ({price})',
  'cart.items': '点',
  'cart.status': '小計',
  'cart.cta': '注文確認',
  'login.title': '会員ログイン',
  'login.subtitle': 'スマホから注文するためには、アカウントにログインが必要です。',
  'login.line-login-button': 'LINEアカウントで利用',
  'login.or': 'もしくは',
  'login.email-address': 'メールアドレス',
  'login.email': 'メールアドレス',
  'login.password': 'パスワード',
  'login.password-hint': '半角英数字で入力してください',
  'login.login-prompt': 'ログイン',
  'login.forgot-password': 'パスワードを忘れた方はこちら',
  'login.signup': 'アカウントをお持ちでない方はこちら',
  'login.signup-prompt': '新規会員登録',
  'login.guest-prompt': 'ゲストとして利用する',
  'signup.title': '新規登録',
  'signup.email': 'メールアドレス',
  'signup.password': 'パスワード',
  'signup.password-hint': '6字以上の半角英数字で入力してください',
  'signup.confirm-password': 'パスワードの確認',
  'signup.confirm-password-hint': '再度パスワードを入力してください',
  'signup.name': 'お名前',
  'signup.terms-hint': '{termsLink}・{privacyLink}に同意しました',
  'signup.terms': '利用規約',
  'signup.privacy-policy': 'プライバシーポリシー',
  'signup.save': '設定の変更を保存',
  'checkout.title': 'お会計',
  'checkout.number-of-items': '点',
  'checkout.subtotal': '小計',
  'checkout.customer-name': 'ユーザー情報：{customerName}',
  'checkout.cta': 'お会計',
  'checkout-modal.title': 'お支払い方法',
  'checkout-modal.pay-in-app': 'スマホで払う',
  'checkout-modal.pay-at-register': 'お店で払う',
  'checkout-modal.cancel': 'キャンセル',
  'checkout-mobile.title': 'スマホで支払う',
  'checkout-mobile.number-of-items': '点',
  'checkout-mobile.subtotal': '小計',
  'checkout-mobile.customer-name': 'ユーザー情報：{customerName}',
  'checkout-mobile.card-information': 'カード情報',
  'checkout-mobile.change-card': 'チェンジカード',
  'checkout-mobile.cvc-hint': 'カード裏面のご署名欄等に印刷されている末尾3桁または4桁の数字です。',
  'checkout-mobile.save-card': 'このカード情報を保存する',
  'checkout-mobile.pay-cta': '支払う',

  'checkout-store.title': 'お店で支払う',
  'checkout-store.instructions': 'この画面をスタッフにお見せください',
  'checkout-store.number-of-items': '点',
  'checkout-store.subtotal': '小計',

  'order-complete.title': 'ありがとうございます！',
  'order-complete.subtitle': 'お食事をお楽しみいただけたようで幸いです。また是非お越し下さいませ！',
  'order-complete.cta': '新規注文を開始する',

  'start-order-form.title': 'ご来店ありがとうございます',
  'start-order-form.subtitle': 'ご来店された人数を選択し、チェックインを押してください',
  'start-order-form.cta': 'チェックインする',
  'orders-subtotal.number-of-items': '点',
  'orders-subtotal.subtotal': '小計',
  'orders-subtotal.customer-name': 'ユーザー情報：{customerName}',
  'call-modal.title': '係員を呼び出しますか？',
  'call-modal.cta-primary': '呼び出す',
  'call-modal.cta-secondary': 'キャンセル',
  'call-modal.confirmed': 'ただいまお伺いいたします。少々お待ちください。',
  'call-modal.cta-close': '閉じる',
}
