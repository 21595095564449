export default (name) => {
  const requestType = `REQUEST_${name}`
  const receiveType = `RECEIVE_${name}`
  const clearType = `CLEAR_${name}`

  const getInitialState = () => ({
    default: {
      data: null,
      error: null,
      loaded: false,
      loading: false,
    },
    lastRequestedKey: 'default',
    lastRecievedKey: 'default',
  })

  return (state = getInitialState(), action) => {
    switch (action.type) {
      case requestType: {
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            error: null,
            loading: true,
          },
          lastRequestedKey: action.key,
        }
      }
      case receiveType: {
        return {
          ...state,
          [action.key]: {
            data: action.data,
            error: action.error,
            loaded: true,
            loading: false,
            receivedAt: Date.now(),
          },
          lastRecievedKey: action.key,
        }
      }
      case clearType: {
        return getInitialState()
      }
      default:
        return state
    }
  }
}
